<template>
  <!-- NAME[epic=销售] 销售汇总按商品 -->
  <div class="orderTest-container">
    <el-form ref="form" :model="form" inline label-position="right">
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <class-select
          ref="classSelect"
          @class-select-change="classChange"
        ></class-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          :duoxuan="true"
          style="width: 160px"
          @brand-select-change="brandChange"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.goods_type"
          placeholder="销售类型"
          :popper-class="'select-idx'"
          filterable
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="item in sale_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="goodsSelect"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click.stop="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click.stop="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handlePrint">打印</el-button>
      </el-form-item>
      <div class="more">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="df">
                <div class="df-a">更多筛选条件</div>
                <!--                <div class="df-b">
                  <el-button
                    type="primary"
                    @click.stop="
                      () => {
                        form.pageSize = 10
                        form.pageNo = 1
                        fetchData()
                      }
                    "
                  >
                    查询
                  </el-button>
                  <el-button type="warning" @click.stop="clearForm">
                    清空
                  </el-button>
                  <el-button>导出</el-button>
                  <el-button>打印</el-button>
                </div>-->
              </div>
            </template>
            <el-form-item label="" prop="depot_id">
              <el-select
                v-model="form.depot_id"
                clearable
                placeholder="仓库"
                style="width: 140px"
              >
                <el-option
                  v-for="(i, idx) in depotSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.depot_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="cust_area_id">
              <el-cascader
                v-model="form.area_id"
                :options="areaList"
                placeholder="选择区域"
                :props="{
                  checkStrictly: true,
                  value: 'id',
                  label: 'name',
                  emitPath: false,
                }"
                clearable
                style="width: 140px"
              ></el-cascader>
              <!-- <el-select
                v-model="form.cust_area_id"
                placeholder="选择区域"
                filterable
                :popper-class="'select-idx'"
                clearable
                style="width: 140px"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="" prop="channel_id">
              <el-select
                v-model="form.channel_id"
                clearable
                style="width: 140px"
                placeholder="渠道"
              >
                <el-option
                  v-for="(i, idx) in channels"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="grade_id">
              <el-select
                v-model="form.grade_id"
                clearable
                style="width: 140px"
                placeholder="客户等级"
              >
                <el-option
                  v-for="(i, idx) in clientLevelSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="staff_id">
              <el-select
                v-model="form.staff_id"
                clearable
                style="width: 140px"
                placeholder="业务员"
              >
                <el-option
                  v-for="(i, idx) in staffSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="driver_id">
              <el-select
                v-model="form.driver_id"
                clearable
                placeholder="司机"
                style="width: 140px"
              >
                <el-option
                  v-for="(i, idx) in shrList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="bill_type">
              <el-select
                v-model="form.bill_type"
                clearable
                style="width: 140px"
                placeholder="来源"
              >
                <el-option
                  v-for="(i, idx) in lyList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="pay_status">
              <el-select
                v-model="form.pay_status"
                clearable
                style="width: 140px"
                placeholder="是否结清"
              >
                <el-option
                  v-for="(i, idx) in jqList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-form>
    <el-table
      ref="tabledata"
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :default-sort="{ prop: null, order: null }"
      :summary-method="summaryFunction"
      @sort-change="sortChange"
    >
      <!--      :default-sort="{ prop: 'gross_profit_amount', order: 'descending' }"-->
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :sortable="item.sort"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <span
            v-if="item.label == '到货数量'"
            style="border-bottom: 2px solid #00a6ff; cursor: pointer"
            @click="cellClick(row)"
          >
            {{ row[item.prop] }}
          </span>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="LSP"
      :data_id="1"
      :type="1"
      :summary-params="form"
    ></ruilang-display-dialog>
    <el-dialog
      :title="goodstitle"
      :visible.sync="goods_show"
      center
      width="80%"
    >
      <div>
        <el-table
          ref="tabledata"
          v-loading="goods_loading"
          stripe
          show-summary
          :summary-method="getSummaries"
          :data="goods_list"
        >
          <el-table-column prop="bill_code" label="单号" width="">
            <template #default="{ row }">
              <span @click="checkOrder(row)">
                {{ row.bill_code }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="cust_name"
            label="店铺名称"
            width=""
          ></el-table-column>
          <el-table-column
            prop="staff_name"
            label="业务员"
            width=""
          ></el-table-column>
          <el-table-column
            prop="goods_type_text"
            label="类型"
            width=""
          ></el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            width=""
          ></el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位"
            width=""
          ></el-table-column>
          <el-table-column
            prop="goods_price"
            label="单价"
            width=""
          ></el-table-column>
          <el-table-column
            prop="goods_money"
            label="金额"
            width=""
          ></el-table-column>
          <el-table-column
            prop="create_at"
            label="时间"
            width=""
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ExprotExml">导 出</el-button>
        <el-button type="primary" @click="goods_show = false">确 定</el-button>
      </span>
    </el-dialog>
    <check-order ref="checkOrder"></check-order>
    <check ref="checkXS" />
  </div>
</template>
<script>
  import _ from 'lodash'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { saleGoodsList, saleTypeList } from '@/api/saleData'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { clientCustChannel } from '@/api/ClientDetail'
  import { getClientLevelList, getStaffList } from '@/api/setPrice'
  import { driverList } from '@/api/advanceOrder'
  import { depotList } from '@/api/depotManagement'
  import { downloadFile, postAction } from '@/api/Employee'
  import { getSelect } from '@/api/saleOrder'
  import { day_n } from '@/utils/Time'
  import check from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import CheckOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'

  export default {
    name: 'SummaryGoods',
    components: {
      CheckOrder,
      check,
      ClassSelect,
      BrandSelect,
      GoodsSearch,
      ClientSearch,
      RuilangDisplayDialog,
    },
    data() {
      return {
        goods_loading: false,
        goods_show: false,
        goods_list: [],
        data_sum: {
          quantity: '',
          goods_money: '',
        },
        Exprotdata: {},
        goodstitle: '',
        activeNames: ['0'],
        loading: false,
        depotSelect: [],
        time: [],
        areaList: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        timeTypeSelect: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
        ],
        staffSelect: [],
        checkList: [
          '品牌',
          '商品编码',
          '商品名称',
          '规格',
          '单位换算',
          '类型',
          // '下单数量',
          '到货数量',
          '单位',
          '单价',
          '金额',
          '毛利',
          '毛利率',
        ],
        columns: [
          {
            order: 1,
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 2,
            label: '商品编码',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 3,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 4,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 5,
            label: '单位换算',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 6,
            label: '类型',
            prop: 'goods_type_text',
            width: '',
          },
          // {
          //   order: 7,
          //   label: '下单数量',
          //   prop: 'pre_quantity',
          //   width: '',
          // },
          {
            order: 7,
            label: '到货数量',
            prop: 'quantity',
            width: '',
          },
          {
            order: 7,
            label: '单位',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 7,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 7,
            label: '金额',
            prop: 'goods_money',
            width: '',
            sort: 'custom',
          },
          {
            order: 7,
            label: '毛利',
            prop: 'gross_profit_amount',
            width: '',
            sort: 'custom',
          },
          {
            order: 7,
            label: '毛利率',
            prop: 'gross_profit',
            width: '',
            sort: 'custom',
          },
        ],
        channels: [],
        clientLevelSelect: [],
        shrList: [],
        lyList: [
          //1、车销 2、访销 3、网销 4、仓库直销
          {
            id: 1,
            name: '车销',
          },
          {
            id: 2,
            name: '访销',
          },
          {
            id: 3,
            name: '网销',
          },
          {
            id: 4,
            name: '仓库直销',
          },
        ],
        jqList: [
          // 0未结清 1已结清 2坏账
          {
            id: 0,
            name: '未结清',
          },
          {
            id: 1,
            name: '已结清',
          },
          {
            id: 2,
            name: '坏账',
          },
        ],
        form: {
          time_type: 'arrive_at',

          start_time: '', //开始时间
          end_time: '', //结束时间
          class_id: '', //品类id
          brand_id: '', //品牌id
          staff_id: '', //业务员id
          driver_id: '', //送货员id
          channel_id: '', //渠道id
          goods_id: '', //商品id
          cust_id: '', //客户id
          area_id: '', //区域id
          grade_id: '', //等级id
          bill_type: '', //单据类型1、车销 2、访销 3、网销 4、仓库直销
          pay_status: '', //结算结果 0未结清 1已结清 2坏账
          pageNo: 1, //页数
          pageSize: 10, //条数
          order: '', //排序方法desc降序asc升序
          sort: '', //排序类型
        },
        url: {
          Export: '/saleAdmin/sale-form/export-goods-new',
        },
        totalAll: {},
        sale_type: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      list(val) {
        console.log(val, 'listbianhua')
      },
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.time = day_n(90)
      this.form.start_time = this.time[0]
      this.form.end_time = this.time[1]
      this.getSelect()
      this.fetchData()
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      checkOrder(row) {
        if (/XS/.test(row.bill_code)) {
          console.log('row', row)
          this.$refs['checkXS'].isshowDialog = true
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkXS'].id = row.order_id
        } else if (/XD/.test(row.bill_code)) {
          this.$refs['checkOrder'].showDialog()
          let text = ''
          if (row.check_status == 0) {
            text = row.bill_status_text
          } else {
            text = row.check_status_text
          }
          // this.$refs['checkOrder'].orderStatus2 = text
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkOrder'].id = row.order_id
        }
        console.log(row)
      },
      ExprotExml() {
        downloadFile(
          '/saleAdmin/sale-form/export-goods-new-detail',
          this.goodstitle + '.xlsx',
          { ...this.form, ...this.Exprotdata }
        ).then((res) => {
          if (res.code == 200) {
            this.$message.success('导出成功')
          }
        })
      },
      cellClick(row) {
        console.log(row, '商品')
        this.Exprotdata = {
          goods_id: row.goods_id,
          goods_type: row.goods_type,
          unit_id: row.unit_id,
          goods_price: row.goods_price,
        }
        postAction('/saleAdmin/sale-form/goods-new-detail', {
          ...this.form,
          goods_id: row.goods_id,
          goods_type: row.goods_type,
          unit_id: row.unit_id,
          goods_price: row.goods_price,
        }).then((res) => {
          if (res.code == 200) {
            this.goods_list = res.data.data.back.data_list
            this.data_sum = res.data.data.back.data_sum
            this.goodstitle =
              res.data.info.goods_name + '(' + res.data.info.goods_spec + ')'
            this.goods_show = true
          }
        })
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        sums[0] = '合计'
        sums[4] = this.data_sum.quantity
        sums[7] = this.data_sum.goods_money
        return sums
      },

      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        switch (prop) {
          case 'goods_money':
            this.form.sort = 'goods_money'
            break
          case 'gross_profit_amount':
            this.form.sort = 'maoli'
            break
          case 'gross_profit':
            this.form.sort = 'bfb'
            break
          default:
            this.form.sort = ''
            break
        }
        // 值 asc 小到大  和  desc  大到小]
        if (order == 'descending') {
          this.form.order = 'desc'
        } else if (order == 'ascending') {
          this.form.order = 'asc'
        } else {
          this.form.sort = ''
          this.form.order = ''
        }
        this.fetchData()
      },
      handleExport() {
        downloadFile(this.url.Export, '销售明细(按商品).xlsx', this.form)
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await saleGoodsList(this.form)
        this.total = totalCount
        this.totalAll = data.all_data
        this.list = data.list
        this.loading = false
      },
      classChange(val) {
        this.form.class_id = val
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      getSelect() {
        // 客户下拉菜单数据获取
        getSelect().then((res) => {
          // this.selectRes = res
          let data = res.data
          this.areaList = data.cust_area //区域
          // this.sale_type = data.sale_type //销售类型
        })
        saleTypeList().then((res) => {
          this.sale_type = res.data
        })
        // 仓库下拉
        depotList().then((res) => {
          this.depotSelect = res.data
        })
        // 渠道下拉
        clientCustChannel().then((d) => {
          this.channels = d.data
        })
        // 客户等级下拉
        getClientLevelList().then((res) => {
          this.clientLevelSelect = res.data
        })
        // 送货人下拉
        driverList().then((res) => {
          this.shrList = res.data
        })
        getStaffList().then((r) => {
          this.staffSelect = r.data
        })
      },
      clearForm() {
        this.time = []
        this.$refs.form.resetFields()
        this.$refs.goodsSearch.resetForm()
        this.$refs.clientSearch.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.classSelect.resetForm()
        this.form.goods_id = ''
        this.form.cust_id = ''
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        let sums = []
        let a = this.totalAll
        sums[0] = '合计' //\n总计
        // sums[2] = a.sale_count
        // sums[3] = a.sale_money // + '\n' + a.rate_money
        // sums[4] = a.give_count
        // sums[5] = a.back_count
        sums[7] = a.goods_num // 到货数量
        sums[10] = a.money // 金额
        sums[11] = a.gross_profit_amount // 毛利
        sums[12] = a.gross_profit //毛利率

        // let val = data.map((item) => {
        //   console.log(item)
        // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[n] = a.toFixed(2) + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {

        //   }
        // })
        console.log('sss', sums)
        return sums
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>
